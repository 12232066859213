<template>
  <TTView>
    <VRow>
      <VCol>
        <ModuleNav :items="items" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { ModuleNav } from '@front.backoffice/shared';

export default {
  name: 'Dashboard',

  components: {
    ModuleNav,
  },

  inject: ['Names'],

  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Адаптивные тесты (talent / skilldev)',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список всех тестов',
              to: { name: this.Names.R_TALENT_TESTS },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать тест',
              to: { name: this.Names.R_TALENT_TEST_CREATE },
            },
          ],
        },
        {
          id: 2,
          title: 'Многоуровневые тесты (multi-level-test)',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список всех тестов',
              to: { name: this.Names.R_MULTI_LEVEL_TEST_TESTS_INDEX },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать тест',
              to: { name: this.Names.R_MULTI_LEVEL_TEST_TESTS_CREATE },
            },
          ],
        },
        {
          id: 3,
          title: 'Конфигурация СМС',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_TALENT_SMS },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_TALENT_SMS_CREATE },
            },
          ],
        },
        {
          id: 4,
          title: 'Условия использования',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_TALENT_TOS_LIST },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_TALENT_TOS_CREATE },
            },
          ],
        },
        {
          id: 5,
          title: 'Экспертная оценка',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список всех опросов',
              to: { name: this.Names.R_EXPERT_ASSESSMENTS_LIST },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать опрос',
              to: { name: this.Names.R_EXPERT_ASSESSMENTS_CREATE },
            },
          ],
        },
        {
          id: 6,
          title: 'Исторические данные',
          children: [
            {
              id: 1,
              icon: '$arrowCircleDown',
              title: 'Загрузить',
              to: { name: this.Names.R_LEARNING_HISTORYDATA_UPLOAD },
            },

          ],
        },
      ],
    };
  },
};
</script>
